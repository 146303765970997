import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: [],
  loading: false,
  error: null,
};

const verifySlice = createSlice({
  name: "verify",
  initialState,
  reducers: {
    verifyLoading(state) {
      state.loading = true;
      state.error = null;
    },
    verifyError(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    setVerifyList(state, action) {
      state.loading = false;
      state.data = action.payload;
    },
  },
});

export const { verifyLoading, verifyError, setVerifyList } =
  verifySlice.actions;

export default verifySlice.reducer;
