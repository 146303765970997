import { Suspense, lazy, useEffect } from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import Loader from "../components/Loader/Loader";
import { useDispatch } from "react-redux";
import { getProfileAction } from "../store/Actions/UpdateProfile";

const LoginForm = lazy(() => import("../pages/Login/LoginForm"));
const ForgotPassword = lazy(() => import("../pages/Login/ForgotPassword"));
const ResetPassword = lazy(() => import("../pages/Login/ResetPassword"));
const RegistrationForm = lazy(() =>
  import("../pages/Registration/RegistrationForm")
);
const Verification = lazy(() => import("../pages/Registration/Verification"));
const Confirmation = lazy(() => import("../pages/Registration/Confirmation"));
const UpdateProfile = lazy(() => import("../pages/Profile/UpdateProfile"));
const ChangeInfo = lazy(() =>
  import("../pages/Profile/ChangeOtherInformation")
);
const UpdatedInfoSuccess = lazy(() =>
  import("../pages/Profile/UpdatedInfoSuccess")
);

const TicketDesign1 = lazy(() => import("../pages/Tickets/Design1"));
const TicketDesign2 = lazy(() => import("../pages/Tickets/Design2"));
const TicketDesign3 = lazy(() => import("../pages/Tickets/Design3"));

const CheckoutDesign1 = lazy(() => import("../pages/checkout/Design1"));
const CheckoutDesign2 = lazy(() => import("../pages/checkout/Design2"));

const Test = lazy(() => import("../pages/checkout/Design1/test"));
export default function Router() {
  const location = useLocation();
  const dispatch = useDispatch();

  const authToken = localStorage.getItem("authToken");

  const scrollToTop = (url) => {
    if (!url.includes("#")) {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  };

  useEffect(() => {
    scrollToTop(window.location.href);
  }, [location]);

  const isLoggedIn = authToken !== null;

  if (isLoggedIn) {
    const fetchData = async () => {
      await dispatch(getProfileAction(authToken));
    };

    fetchData();
  }
  return (
    <>
      <Routes>
        <Route path="/" element={<Navigate to="/ticket1" />} />

        <Route
          path="/ticket1"
          element={
            <Suspense fallback={<Loader />}>
              <TicketDesign1 />
            </Suspense>
          }
        />

        <Route
          path="/ticket2"
          element={
            <Suspense fallback={<Loader />}>
              <TicketDesign2 />
            </Suspense>
          }
        />

        <Route
          path="/ticket3"
          element={
            <Suspense fallback={<Loader />}>
              <TicketDesign3 />
            </Suspense>
          }
        />

        <Route
          path="/checkout1"
          element={
            <Suspense fallback={<Loader />}>
              <CheckoutDesign1 />
            </Suspense>
          }
        />

        <Route
          path="/checkout2"
          element={
            <Suspense fallback={<Loader />}>
              <CheckoutDesign2 />
            </Suspense>
          }
        />

        <Route
          path="/test"
          element={
            <Suspense fallback={<Loader />}>
              <Test />
            </Suspense>
          }
        />

        {!isLoggedIn && (
          <>
            <Route
              path="/login"
              element={
                <Suspense fallback={<Loader />}>
                  <LoginForm />
                </Suspense>
              }
            />

            <Route path="/" element={<Navigate to="/login" />} />

            <Route
              path="/forgot-password"
              element={
                <Suspense fallback={<Loader />}>
                  <ForgotPassword />
                </Suspense>
              }
            />

            <Route
              path="/reset-password"
              element={
                <Suspense fallback={<Loader />}>
                  <ResetPassword />
                </Suspense>
              }
            />

            <Route
              path="/register"
              element={
                <Suspense fallback={<Loader />}>
                  <RegistrationForm />
                </Suspense>
              }
            />

            <Route
              path="/verification"
              element={
                <Suspense fallback={<Loader />}>
                  <Verification />
                </Suspense>
              }
            />

            <Route
              path="/confirmation"
              element={
                <Suspense fallback={<Loader />}>
                  <Confirmation />
                </Suspense>
              }
            />

            <Route path="*" element={<Navigate to="/login" />} />
          </>
        )}

        {/* Other routes accessible only if logged in */}
        {isLoggedIn && (
          <>
            <Route
              path="/update-profile"
              element={
                <Suspense fallback={<Loader />}>
                  <UpdateProfile />
                </Suspense>
              }
            />

            <Route
              path="/change-information"
              element={
                <Suspense fallback={<Loader />}>
                  <ChangeInfo />
                </Suspense>
              }
            />

            <Route
              path="/update-success"
              element={
                <Suspense fallback={<Loader />}>
                  <UpdatedInfoSuccess />
                </Suspense>
              }
            />

            <Route path="*" element={<Navigate to="/update-profile" />} />
          </>
        )}

        <Route path="404" element={<>Not Found</>} />
        <Route path="*" element={<Navigate to="/404" />} />
      </Routes>
    </>
  );
}
