import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: [],
  loading: false,
  error: null,
};

const loginSlice = createSlice({
  name: "login",
  initialState,
  reducers: {
    loginLoading(state) {
      state.loading = true;
      state.error = null;
    },
    loginError(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    setLoginList(state, action) {
      state.loading = false;
      state.data = action.payload;
    },
  },
});

export const { loginLoading, loginError, setLoginList } = loginSlice.actions;

export default loginSlice.reducer;
