import { createSlice } from "@reduxjs/toolkit";

const initialContentState = {
  data: [],
  loading: false,
  error: null,
  thisPage: 0,
  allPages: 0,
};

const initialState = {
  data: [],
  loading: false,
  error: null,
  initialContentState,
};

const ticketsSlice = createSlice({
  name: "ticketsSlice",
  initialState,
  reducers: {
    ticketsLoading(state) {
      state.loading = true;
      state.error = null;
    },
    ticketsError(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    setTicketsList(state, action) {
      state.loading = false;
      state.thisPage = action.payload.thisPage;
      state.allPages = action.payload.allPages;
      state.data =
        action.payload?.thisPage === 1
          ? action.payload?.data
          : [...state?.data, ...action.payload?.data];
    },

    contentLoading(state) {
      state.initialContentState.loading = true;
      state.error = null;
    },
    contentError(state, action) {
      state.initialContentState.loading = false;
      state.error = action.payload;
    },
    setContentList(state, action) {
      state.initialContentState.loading = false;
      state.initialContentState.data = action.payload;
    },
  },
});

export const {
  ticketsLoading,
  ticketsError,
  setTicketsList,
  contentLoading,
  contentError,
  setContentList,
} = ticketsSlice.actions;

export default ticketsSlice.reducer;
