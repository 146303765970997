import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: [],
  loading: false,
  error: null,
};

const checkoutSlice = createSlice({
  name: "checkout",
  initialState,
  reducers: {
    checkoutLoading(state) {
      state.loading = true;
      state.error = null;
    },
    checkoutError(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    setCheckoutList(state, action) {
      state.loading = false;
      state.data = action.payload;
    },
  },
});

export const { checkoutLoading, checkoutError, setCheckoutList } =
  checkoutSlice.actions;

export default checkoutSlice.reducer;
